import React from "react"
import { Link } from "gatsby"
import Grid from "../elements/grid"
import Icon from "../elements/icon"
import { SizedText, Canon } from "../elements/typography"
import { StaticImage } from "gatsby-plugin-image"
import quoteIcon from "../../theme/icons/quote.svg"

const ReviewBox = ({text, name, children}) => (
  <div css={{
    width: `100%`,
    minWidth: `260px`,
    backgroundColor: `hsla(0,0%,100%,.75)`,
    boxShadow: `0 1px 2px rgba(0,0,0,0.15)`,
    marginBottom: `2rem`,
    borderRadius: `16px`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`,
    '& p': {
      fontWeight: `600`,
      '& span': {
        fontWeight: `400`
      }
    }, 
    '@media (min-width: 736px)': { width: `49% !important`, }, 
  }}>
    <div css={{ 
      padding: `2rem 2rem 1rem 2rem`,
      display: `flex`,
    }}>
      <div css={{marginRight: `1rem`}}>
        <span css={{paddingLeft: `0.75rem`, fontSize: `1.25rem`, color: `#bdc3c7`}}><Icon icon={quoteIcon} overrides={{verticalAlign: `-0.25rem !important`}}/></span>
      </div>
      <div>
      {text}
      </div>
    </div>
    <div>
    <div css={{
      display: `flex`,
      justifyContent: `flex-end`,
    }}>
      {children}
    </div>
    <div css={{ borderTop: `1px solid #bdc3c7`, padding: `1rem 2rem 2rem 2rem`, marginTop: `-40px`}}>
      <span>{name}</span><br />
      <span>&#9733; &#9733; &#9733; &#9733; &#9733;</span>
    </div>
    </div>
  </div>
)

const ReviewSection = () => (
    <>
        <Grid.FullWidthSection styles={{
          position: `relative`,
          padding: `4rem 0 3rem 0`,
          alignItems: `center`,
          overflowX: `hidden`,
          zIndex: `8`,
          '@media (min-width: 1495px)': { padding: `4rem 0` } 
        }}>
          <Grid.Block start={[2]} span={[12]} css={{
            margin: `2rem 0`,
            textAlign: `center`,
          }}> 
            <Canon styleOverrides={{ marginBottom: `0.25rem`}}>Don't Just Take Our Word For It</Canon>
            <SizedText smallSize="1.424" largeSize="2.027" styleOverrides={{ fontWeight: `400`, marginBottom: `3rem`}}>
              See what these actual customers have to say about banking with TCB, and this is just a few
            </SizedText>
          </Grid.Block>
          <Grid.Block start={['2','2','2','2','2','3']} span={['12','12','12','12','12','10']} >
          <div css={{display: `flex`, justifyContent: `space-between`, flexWrap: `wrap`}}>
            <ReviewBox 
              text="I was not happy with the service from my previous bank; they treated my wife and I like we were just a number and not loyal customers of 30 years.  The former president of that bank recommended TCB and said that we would be happy there and that they offer the same hometown courteous friendly service and products we offered. He also said the new head of TCB in Richmond Hill was a great guy, Chris Barr, and we would be pleased with his service. He was right. The service and communication have been spot on.  His honesty, integrity and advice has been above reproach. I Whole heartedly endorse TCB and highly recommend anyone considering doing business with them." 
              name="Larry Baker">
                <StaticImage src="../../images/people/lary-baker.jpg" height={80} css={{borderRadius: `50% !important`, overflow: `hidden`, marginRight: `2.5rem`, 'img': {borderRadius: `50% !important`}}}/>    
            </ReviewBox>
            <ReviewBox text="It was such a pleasure buying a home through the Claxton Bank. The process was very easy. We were treated like family, the moment we walk through the doors. Customer service was awesome. My loan officer Mrs. Emily Hall was such a pleasure and helpful person through this process. We greatly appreciate everyone and their professional services that was shown to us. Again thank you so much Kay Blocker for your kindness and for introducing us to Emily. Professional service at its best!" 
              name="Deborah Harris">
                <StaticImage src="../../images/people/deborah-harris.png" height={80} css={{borderRadius: `50% !important`, overflow: `hidden`, marginRight: `2.5rem`, 'img': {borderRadius: `50% !important`}}}/>    
            </ReviewBox>
            </div>
          </Grid.Block>
        </Grid.FullWidthSection>
        <Grid.FullWidthSection css={{padding: `0 0 4rem 0`, alignItems: `center`, }}>
        <Grid.Block start={['2', '2', '2', '2','2','3']} span={['12','12', '12', '12', '12','10']} css={{
          backgroundColor: `#3498db`,
          textAlign: `center`,
          boxShadow: `1px 7px 21px -15px rgba(0,0,0,0.75), 1px -7px 21px -15px rgba(0,0,0,0.75)`,
          padding: `3rem 2rem 5rem 2rem`,
          '@media (min-width: 1495px)': { padding: `6rem` } 
          
        }}>
          <Canon styleOverrides={{ marginBottom: `0.25rem`}}>Ready to get started?</Canon>
          <SizedText smallSize="1.424" largeSize="2.027" styleOverrides={{ fontWeight: `400`, marginBottom: `3rem`}}>
            What are you waiting for? Be a part of banking made simple. 
          </SizedText>
          <a href="https://theclaxtonbank.csinufund.com/home/newUser" css={{ 
            border: `2px solid #fff`,
            borderRadius: `0.5rem`,
            padding: `1rem 2rem`,
            fontSize: `1.266rem`,
            color: `#fff !important`,
            textDecoration: `none`,  
          }}>
            Open an Account Today
          </a>
        </Grid.Block>
      </Grid.FullWidthSection>
    </>
)

export default ReviewSection